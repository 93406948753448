import { render, staticRenderFns } from "./StatisticsTopFive.vue?vue&type=template&id=6db79f18&scoped=true&"
import script from "./StatisticsTopFive.vue?vue&type=script&lang=js&"
export * from "./StatisticsTopFive.vue?vue&type=script&lang=js&"
import style0 from "./StatisticsTopFive.vue?vue&type=style&index=0&id=6db79f18&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6db79f18",
  null
  
)

export default component.exports