<template>
  <Dashboard />
</template>

<script>
import Dashboard from "@/components/Dashboard/Dashboard.vue";

export default {
  name: "DashboardView",

  components: {
    Dashboard,
  },
};
</script>
